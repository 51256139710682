"use client";
import React from "react";
import { BsFacebook, BsTwitterX } from "react-icons/bs";
import { ImLinkedin2 } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import Link from "next/link";
import { usePathname } from "next/navigation";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const pathname = usePathname();
  return (
    <div>
      <footer className="text-center">
        <div className="container pb-4 pt-0">
          <hr />
          <div className="row">
            <div className="col-lg fs-6  text-lg-start text-center pb-3 pb-lg-0">
              Copyright © {currentYear}, Scholar Earth. All rights reserved.
            </div>
            <div className="col-lg fs-6 text-lg-start text-center">
              <ul className="list-unstyled d-flex gap-5 justify-content-center footerBottom fs-5">
                <li>
                  <Link
                    href="https://www.facebook.com/scholarearthlimited/"
                    title="Facebook"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    <BsFacebook />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.linkedin.com/company/scholar-earth-limited"
                    title="linkedin"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    <ImLinkedin2 size={22} />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.instagram.com/scholar_earth/"
                    title="instagram"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    <FaInstagram />
                  </Link>
                </li>

                <li>
                  <Link
                    href="https://twitter.com/ScholarEarth"
                    title="twitter"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    <BsTwitterX size={18} />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg fs-6 text-lg-end text-center">
              <Link
                href="/terms-and-conditions"
                className="text-decoration-none text-black"
              >
                <span
                  className={` ${
                    pathname === "/terms-and-conditions"
                      ? "text-sky text-decoration-none border-bottom"
                      : "text-black text-decoration-none "
                  }`}
                >
                  Terms and Conditions
                </span>
              </Link>{" "}
         <span className="px-2">|</span>
              <Link
                href="/privacy-policy"
                className="text-decoration-none text-black"
              >
                <span
                  className={` ${
                    pathname === "/privacy-policy"
                      ? "text-sky text-decoration-none border-bottom"
                      : "text-black text-decoration-none "
                  }`}
                >
                  Privacy Policy
                </span>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
