import Link from "next/link";
import React, { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

const Menu = ({ pathname, menuItems }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="d-flex gap-3 flex-xl-row flex-column pt-3 pt-xl-0 pe-xl-3 pe-0">
      {menuItems?.map((menuItem, idx) => (
        <React.Fragment key={idx}>
          {menuItem.dropdown ? (
            <>
              <div className="top-dropdown">
                <div className="cursor-pointer">
                  <div
                    className="d-flex justify-content-between"
                    onClick={toggleDropdown}
                  >
                    {menuItem.label}
                    <RiArrowDropDownLine size={28} />
                  </div>
                  {isMobile ? (
                    <>
                      {isDropdownOpen && (
                        <div className="dropdown-box">
                          {menuItem.dropdownItems.map((dropdownItem) => (
                            <Link
                              key={dropdownItem.path}
                              href={dropdownItem.path}
                              className={`
                ${
                  pathname === dropdownItem.path
                    ? "text-greenish text-decoration-none bg-transparent border-0 "
                    : "text-black text-decoration-none bg-transparent border-0 "
                } fs-6
                `}
                            >
                              {dropdownItem.label}
                            </Link>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {
                        <div className="dropdown-box">
                          {menuItem.dropdownItems.map((dropdownItem) => (
                            <Link
                              key={dropdownItem.path}
                              href={dropdownItem.path}
                              className={`${
                                pathname === dropdownItem.path
                                  ? "text-greenish text-decoration-none bg-transparent border-0 "
                                  : "text-black text-decoration-none bg-transparent border-0 "
                              } fs-6
                `}
                            >
                              {dropdownItem.label}
                            </Link>
                          ))}
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Link
              href={menuItem.path}
              className={
                pathname === menuItem.path
                  ? "text-greenish text-decoration-none"
                  : "text-black text-decoration-none"
              }
            >
              {menuItem.label}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Menu;
