// export const menuItems = [
//   {
//     path: "/our-program",
//     label: "Our Programs",
//     dropdown: true,
//   },
//   {
//     path: "/test-prep",
//     label: "Test Prep",
//     dropdown: true,
//   },
//   {
//     path: "/events",
//     label: "Events",
//     dropdown: false,
//   },
//   {
//     path: "/scholarships",
//     label: "Scholarships",
//     dropdown: false,
//   },
//   // {
//   //   path: "/blog",
//   //   label: "Blog",
//   // },
//   {
//     path: "/about",
//     label: "About",
//     dropdown: false,
//   },
// ];
export const menuItems = [
  { id: 1, path: "/enquire-now", label: "Enquire Now", dropdown: false },
  {
    id: 2,
    dropdown: true,
    label: "Our Programs",
    dropdownItems: [
      { id: 1, path: "/our-program/study-in-us", label: "Study in the US" },
      { id: 2, path: "/our-program/study-in-uk", label: "Study in the UK" },
      {
        id: 3,
        path: "/our-program/study-in-ireland",
        label: "Study in Ireland",
      },
      {
        id: 4,
        path: "/our-program/study-in-australia",
        label: "Study in Australia",
      },
      { id: 5, path: "/our-program/study-in-canada", label: "Study in Canada" },
      { id: 6, path: "/our-program/study-in-europe", label: "Study in Europe" },
      // { id: 7, path: "/our-program/spotlight-ASU", label: "Spotlight: ASU" },
    ],
  },
  {
    id: 3,
    dropdown: true,
    label: "Test Prep",
    dropdownItems: [
      { id: 1, path: "/test-prep/ilets", label: "IELTS" },
      { id: 2, path: "/test-prep/toefl", label: "TOEFL" },
      { id: 3, path: "/test-prep/gre", label: "GRE" },
      { id: 4, path: "/test-prep/gmat", label: "GMAT" },
      { id: 5, path: "/test-prep/sat", label: "SAT" },
      { id: 6, path: "/test-prep/pte", label: "PTE" },
    ],
  },
  { id: 4, path: "/events", label: "Events", dropdown: false },
  { id: 5, path: "/blog", label: "Blog", dropdown: false },
  {
    id: 6,
    dropdown: true,
    label: "Scholarships",
    dropdownItems: [
      {
        id: 1,
        path: "/scholarships/us-scholarships",
        label: "US Scholarships",
      },
      {
        id: 2,
        path: "/scholarships/uk-scholarships",
        label: "UK Scholarships",
      },
      {
        id: 3,
        path: "/scholarships/ireland-Scholarships",
        label: "Ireland Scholarships",
      },
      {
        id: 4,
        path: "/scholarships/australia-scholarships",
        label: "Australia Scholarships",
      },
      {
        id: 5,
        path: "/scholarships/canada-scholarships",
        label: "Canada Scholarships",
      },
      {
        id: 6,
        path: "/scholarships/europe-scholarships",
        label: "Europe Scholarships",
      },
      {
        id: 7,
        path: "/scholarships/international-scholarships",
        label: "International Scholarships",
      },
    ],
  },
  { id: 7, path: "/about", label: "About", dropdown: false },
];

export const Routes = {
  LOGIN: {
    pathName: "login",
    absolutePath: "/login",
  },
  REGISTER: {
    pathName: "register",
    absolutePath: "/register",
  },
  VERIFY: {
    pathName: "verify",
    absolutePath: "/verify",
  },
};

export const ADMIN_ROUTES = {
  DASHBOARD: {
    pathName: "dashboard",
    absolutePath: "/dashboard",
  },
  MY_DOCUMENT: {
    pathName: "my-document",
    absolutePath: "/my-document",
  },
  ALL_COURSE_FINDER: {
    pathName: "all-course-finder",
    absolutePath: "/university-finder",
  },
  OTHERS_DOCUMENT: {
    pathName: "others-document",
    absolutePath: "/others-document",
  },
  COUNSELLING_SESSIONS: {
    pathName: "counselling-sessions",
    absolutePath: "/counselling-sessions",
  },
  LOANS: {
    pathName: "loans",
    absolutePath: "/resources/loans-other-information",
  },
  VISAS: {
    pathName: "visas",
    absolutePath: "/resources/visa-information",
  },
  MYPROFILE: {
    pathName: "my-profile",
    absolutePath: "/my-profile",
  },
};

export const menuLoginItems = [
  { id: 1, path: "/dashboard", label: "My Account", dropdown: false },
]
