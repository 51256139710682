import React, { useEffect, useState } from "react";
import Modal from "react-modal";

export const CommonModal = ({ children, width, isOpen, closeModal }) => {
  const [modalStyles, setModalStyles] = useState({});

  const customStyles = {
    content: {
      top: "50%",
      left: "100%",
      right: "0",
      bottom: "auto",
      marginRight: "-50%",
      width: width ? width : "568px",
      border: "none",
      zIndex: "100",
      overflow: "hidden",
      background: "rgb(250 250 250)",
      padding: "41px",
    },
  };

  const mobileStyles = {
    content: {
      ...customStyles.content,
      width: "100%",
    },
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setModalStyles(mobileStyles);
      } else {
        setModalStyles(customStyles);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
        <Modal style={modalStyles} isOpen={isOpen} onRequestClose={closeModal}>
          {children}
        </Modal>
    </>
  );
};
