"use client";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { usePathname, useRouter } from "next/navigation";
import Menu from "../../organisms/header_menu/index";
import { Routes, menuItems, menuLoginItems } from "../../../constants/routes";
import Head from "next/head";
import { ACCESS_TOKEN } from "../../../constants/common";
const Header = ({ pageTitle }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [token, setToken] = useState(null);
  const [isTokenLoading, setisTokenLoading] = useState(true);
  useEffect(() => {
    setisTokenLoading(true);
    const storedToken = localStorage.getItem(ACCESS_TOKEN);
    setToken(storedToken);
    setisTokenLoading(false);
  }, [token]);
  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    router.push(Routes.LOGIN.absolutePath);
  };
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Navbar expand="xl" className="sticky-top bg-white  shadow-sm">
        <div className="container ps-4 ps-md-0">
          <Navbar.Brand className="text-black fs-2 fw-bold">
            <Link href="/">
              <span>
                <img src={"/images/Logo.png"} className="mainlogo" alt="Study abroad" />
              </span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav " className="header_links">
            <Nav className="ms-auto  text-black fs-18">
              <Menu pathname={pathname} menuItems={menuItems} />
              {isTokenLoading ? null : (
                <>
                  {token && (
                      <Menu pathname={pathname} menuItems={menuLoginItems} />
                  )}
                </>
              )}
            </Nav>
            
            {isTokenLoading ? null : (
              
              
              <>
                {!token ? (
                  <Nav>
                    <Link href={Routes.LOGIN.absolutePath}>
                    <div className="my-3">
                        <button
                          className="btn bg-greenish text-white px-3 py-2 "
                          type="button"
                        >
                          Sign In / Sign Up
                        </button>
                      </div>
                    </Link>
                  </Nav>
                ) : (
                  <Nav>
                    <div className="my-3">
                      <button
                        className="btn bg-greenish text-white px-3 py-1 "
                        type="button"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </div>
                  </Nav>
                )}
              </>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
