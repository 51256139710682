"use client";
import { useEffect, useState } from "react";
import { CommonModal } from "../modal/index";
import Image from "next/image";
export const CookiesModal = () => {
  const [show, setShow] = useState(false);

  const handleCookieAction = (accept) => {
    localStorage.setItem("cookies", accept);
    setShow(false);
  };

  useEffect(() => {
    const cookie = localStorage.getItem("cookies");
    if (!cookie) {
      setShow(true);
    }
  }, []);

  return (
    <div className="cookie-section">
      {show && (
        <CommonModal isOpen={true}>
          <div className="cookie-banner">
          <div className="d-flex gap-3 px-4">
            <Image
              alt="loading"
              height={50}
              width={50}
              loading="lazy"
              src={"/images/cookies.png"}
            />
            <p>
              This website uses cookies. By using this site, you can choose to
              accept or reject cookies.
            </p>
            </div>
            <div className="d-flex align-items-center gap-3 justify-content-center">
              <a
                className="btn bg-greenish text-white  px-4 py-2"
                role="button"
                onClick={() => handleCookieAction("true")}
              >
                Accept
              </a>
              <a
                className="btn bg-greenish text-white  px-4 py-2"
                role="button"
                onClick={() => handleCookieAction("false")}
              >
                Reject
              </a>
            </div>
          </div>
        </CommonModal>
      )}
    </div>
  );
};
